<template>
  <div class="w-max rounded-xl bg-white p-2 shadow-md md:p-3px">
    <div
      class="flex justify-between gap-3 text-neutral-300"
      :class="
        hasSecondary
          ? 'text-[12px] leading-[110%] md:text-body-15-regular-px'
          : 'text-body-15-regular-px'
      "
    >
      <span v-if="size === 'md'" class="hidden md:inline">{{ name }}</span>
      <span>{{ formattedDate ?? name }}</span>
    </div>
    <div class="mt-1 flex flex-col gap-0.5 md:mt-0 md:gap-1">
      <div class="flex items-center justify-between gap-2 md:gap-3">
        <span
          class="inline-flex items-center justify-center rounded-full"
          :style="{
            width: `${imgSize}px`,
            height: `${imgSize}px`,
            ...imgWrapperStyle
          }"
        >
          <Image
            :src="img"
            :width="imgSize"
            :height="imgSize"
            loading="lazy"
            :style="imgStyle"
            class="max-h-full"
          />
        </span>
        <span
          :class="
            hasSecondary
              ? [
                  'text-body-15-regular',
                  size === 'sm' ? 'md:text-body-20-regular-px' : 'md:text-body-22-regular-px'
                ]
              : 'text-body-20-regular md:text-body-27-regular-px'
          "
        >
          {{ format ? format(value) : useLocaleCurrency(value) }}
        </span>
      </div>
      <div v-if="hasSecondary" class="flex items-center justify-between gap-2 md:gap-3">
        <span
          class="inline-flex items-center justify-center rounded-full"
          :style="{
            width: `${imgSize}px`,
            height: `${imgSize}px`,
            ...secondaryImgWrapperStyle
          }"
        >
          <Image
            :src="secondaryImg!"
            :width="imgSize"
            :height="imgSize"
            loading="lazy"
            class="max-h-full"
            :style="secondaryImgStyle"
          />
        </span>
        <span
          class="text-body-15-regular"
          :class="size === 'sm' ? 'md:text-body-20-regular-px' : 'md:text-body-22-regular-px'"
        >
          {{ format ? format(secondaryValue) : useLocaleCurrency(secondaryValue!) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue'
import screens from '#tailwind-config/theme/screens'

export interface TooltipProps {
  name?: string
  img: string
  imgStyle?: CSSProperties
  imgWrapperStyle?: CSSProperties
  date?: number
  dateFormat?: string
  value: number
  title?: string
  secondaryImg?: string
  secondaryImgStyle?: CSSProperties
  secondaryImgWrapperStyle?: CSSProperties
  secondaryValue?: number
  size?: 'sm' | 'md'
  format?: (value?: number) => string | undefined
}

const props = withDefaults(defineProps<TooltipProps>(), {
  dateFormat: 'DD/MM/YYYY',
  size: 'md'
})

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')

const formattedDate = computed(() =>
  props.date ? useLocaleDateFormat(props.date, props.dateFormat).value : undefined
)
const hasSecondary = computed(() => props.secondaryImg && props.secondaryValue !== undefined)
const imgSize = computed(() =>
  hasSecondary.value ? (isMd.value ? 26 : 15) : isMd.value ? 34.5 : 24
)
</script>
